import type { Footer as FooterType } from "__generated__/sanity/sanitySchema";
import { IconArrowUpRightLine } from "@daangn/react-monochrome-icon";
import React from "react";

import Facebook from "~/assets/Facebook";
import Instagram from "~/assets/Instagram";
import Line from "~/assets/Line";
import Note from "~/assets/Note";
import Twitter from "~/assets/Twitter";
import Youtube from "~/assets/Youtube";
import { Link, useLocale } from "~/remix-lib";
import { checkExternalLink } from "~/utils/checkExternalLink";

import { GtmVariableName } from "../utils/GtmVariable";
import * as css from "./Footer.css";
import KarrotLogo from "./KarrotLogo";
import PageMaxWidthWrapper from "./PageMaxWidthWrapper";

export type FooterProps = {
  data: FooterType | null;
};

const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useLocale();

  return (
    <footer>
      <PageMaxWidthWrapper>
        <div className={css.container}>
          <div className={css.top}>
            <div className={css.logoAndSocialButtons}>
              <div className={css.logo}>
                <KarrotLogo location="footer" />
              </div>
              <div className={css.socialButtons}>
                {props.data?.FooterSocialSection?.map((socialButton, l) => (
                  <Link
                    data-gtm={GtmVariableName.FooterSocial}
                    className={css.socialButton}
                    href={socialButton?.SocialLink ?? "/"}
                    aria-label={`Go to Karrot ${socialButton?.SocialName}`}
                    key={l}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {(() => {
                      switch (socialButton?.SocialName) {
                        case "facebook":
                          return <Facebook />;
                        case "instagram":
                          return <Instagram />;
                        case "youtube":
                          return <Youtube />;
                        case "note":
                          return <Note width={24} height={24} />;
                        case "line":
                          return <Line />;
                        case "twitter":
                          return <Twitter style={{ padding: 3 }} />;
                      }
                    })()}
                  </Link>
                ))}
              </div>
            </div>
            <div className={css.navSections}>
              {props.data?.FooterNavSections?.map((navSection, i) => (
                <div className={css.navSection} key={i}>
                  <div className={css.navSectionTitle}>
                    {navSection?.NavLinksSectionTitle}
                  </div>
                  <div className={css.navSectionLinks}>
                    {navSection?.NavLink?.map((link, j) => {
                      if (link?.NavLinkExternal) {
                        return (
                          <a
                            data-gtm={GtmVariableName.FooterNavigation}
                            className={css.navSectionLink}
                            href={link?.NavLinkLink ?? "/"}
                            key={j}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {link?.NavLinkLabel}
                            {link?.SuffixArrowIcon && (
                              <IconArrowUpRightLine
                                className={css.linkExternalIcon}
                                size={12}
                              />
                            )}
                          </a>
                        );
                      }

                      return (
                        <Link
                          data-gtm={GtmVariableName.FooterNavigation}
                          className={css.navSectionLink}
                          href={link?.NavLinkLink ?? "/"}
                          key={j}
                          target={
                            checkExternalLink(link?.NavLinkLink) ||
                            link?.NavLinkExternal
                              ? "_blank"
                              : "_self"
                          }
                        >
                          {link?.NavLinkLabel}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={css.divider} />
          <div className={css.bottom}>
            <div className={css.copyright}>
              <div className={css.copyrightCompanyName}>
                {t("Footer.copyright_company_name")}
              </div>
              <div className={css.copyrightDescription}>
                {t("Footer.copyright_description")}
              </div>
            </div>
            {props.data?.FooterBottomSection && (
              <div className={css.bottomLinks}>
                {props.data?.FooterBottomSection?.map((link, k) => (
                  <Link
                    className={css.bottomLink}
                    href={link?.NavLinkLink ?? "/"}
                    key={k}
                  >
                    {link?.NavLinkLabel}
                    {link?.NavLinkExternal && (
                      <IconArrowUpRightLine
                        className={css.linkExternalIcon}
                        size={12}
                      />
                    )}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </PageMaxWidthWrapper>
    </footer>
  );
};

export default Footer;
