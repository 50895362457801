import { Box } from "@daangn/carotene";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import React from "react";

import { default as GlobalGlobalNavigationBar } from "~/figma-components/GlobalNavigationBar";
import { useRootLoaderData } from "~/remix-lib";
import Footer from "~/services/Footer";
import { default as KRGlobalNavigationBar } from "~/services/gnb/kr/GlobalNavigationBar";
import KRFooter from "~/services/KRFooter";
import { services } from "~/utils/services";

import { useLocationSelectModal } from "./figma-components/useLocationSelectModal";
import * as css from "./GlobalLayout.css";
import AppDownloadBanner from "./services/AppDownloadBanner";
import { LocationSelectContext } from "./services/gnb/LocationSelectContext";
import { SkipLink } from "./SkipLink";

type PageType = "index" | "detail" | "error" | "default";
type FeatureFlag = "showAppDownloadBanner" | "showGNB" | "showFooter";

type RootLayoutFeatureFlag = {
  [key in FeatureFlag]?: boolean;
};

const MAIN_CONTENT = "main-content";

const karrotmarketApiService = services.getInstance().karrotmarket;

async function findKROnlyByRegionName(regionName: string) {
  const krLocations = await karrotmarketApiService.searchLocation({
    karrotLocalCountryCode: KarrotLocalCountryCode.KR,
    keyword: regionName,
  });

  return {
    allRegions: krLocations.locations.map((location) => ({
      ...location,
      countryName: KarrotLocalCountryCode.KR,
    })),
  };
}

type RootLayoutProps = {
  children: React.ReactNode;
  pageType?: PageType;
  flags?: RootLayoutFeatureFlag;
};
export const RootLayout: React.FC<RootLayoutProps> = ({
  children,
  pageType = "default",
  flags = {},
}) => {
  const rootData = useRootLoaderData();

  const {
    loading: locationSelectModalLoading,
    error: locationSelectModalError,
    locationInput,
    allCountriesLocations,
    onChangeLocationInput,
    searchCurrentPosition,
    resetLocations,
    resetError,
  } = useLocationSelectModal({
    initLocations: rootData.__locationSelectModal.fallbackLocations,
    findAllByRegionName:
      rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR
        ? findKROnlyByRegionName
        : karrotmarketApiService.findAllRegion,
    findLocationsByCoordOrKeyword: karrotmarketApiService.searchLocation,
  });

  const isKR = rootData.karrotLocalCountryCode === KarrotLocalCountryCode.KR;
  const GlobalNavigationBar = isKR
    ? KRGlobalNavigationBar
    : GlobalGlobalNavigationBar;

  const defaultFlags = {
    showAppDownloadBanner: true,
    showGNB: true,
    showFooter: true,
  };

  const mergedFlags = { ...defaultFlags, ...flags };

  return (
    <LocationSelectContext.Provider
      value={{
        loading: locationSelectModalLoading,
        error: locationSelectModalError,
        locationInput,
        allCountriesLocations,
        onChangeLocationInput,
        searchCurrentPosition,
        resetLocations,
        resetError,
      }}
    >
      <header>
        <SkipLink href={`#${MAIN_CONTENT}`} className={css.skipLink} />
        {mergedFlags.showGNB ? (
          <GlobalNavigationBar
            data={rootData.__gnb.data}
            query={rootData.__gnb.query}
            showFixedTopSearchBar={pageType === "default"}
            showNavigationMenu={pageType !== "index"}
            isDetailPage={pageType === "detail"}
            isIndexPage={pageType === "index"}
          />
        ) : null}
      </header>
      <main id={MAIN_CONTENT} className={css.main}>
        {children}
        {mergedFlags.showAppDownloadBanner ? (
          <Box marginTop={{ base: 12, small: 20, medium: 24 }}>
            <AppDownloadBanner data={rootData.__appDownloadBanner.data} />
          </Box>
        ) : null}
      </main>
      {mergedFlags.showFooter &&
        (isKR ? (
          <KRFooter data={rootData.__footer.data} />
        ) : (
          <Footer data={rootData.__footer.data} />
        ))}
    </LocationSelectContext.Provider>
  );
};
